import React from "react";
import PropTypes from "prop-types";
import ErrorContainer from "checkout/form/ErrorContainer";
import styles from "./third_party_consent_item.module.sass";

function ThirdPartyConsentItem(props) {
  const { content, field } = props;
  const onChange = props.onChange || field.onChange;
  const checked = props.checked || !!field.checked;
  const name = props.name || field.name;
  return (
    <ErrorContainer className={styles.third_party_consent} field={field}>
      <div className={styles.consent_row}>
        <div className={styles.consent_logo}>{content.img()}</div>
        <div className={styles.consent_rhs}>
          <div className={styles.consent_explainer}>{content.explainer()}</div>
          <label htmlFor={name} className={styles.consent_checkbox}>
            <input type="checkbox" name={name} checked={checked} onChange={onChange}/>
            <span>{content.label()}</span>
          </label>
        </div>
      </div>
    </ErrorContainer>
  );
}

ThirdPartyConsentItem.propTypes = {
  content: PropTypes.shape({
    img: PropTypes.func.isRequired,
    explainer: PropTypes.func.isRequired,
    label: PropTypes.func.isRequired
  }).isRequired,
  field: PropTypes.shape({
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    name: PropTypes.string
  }),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string
};

export default ThirdPartyConsentItem;
