import { ca_provinces, it_provinces, us_states, au_states } from "common/countries";

export function fixState(field, options) {
  if (options && options.length === 1) {
    return { ...field, value: options[0].value };
  } else if (options && options.map(opt => opt.value).includes(field.value)) {
    return field;
  } else {
    return { ...field, value: "" };
  }
}

export function stateOptions(country, allowedOptions) {
  const info = statesInCountry(country);
  if (!info.options || !allowedOptions) {
    return info;
  }

  const filteredOptions = info.options.filter(opt =>
    allowedOptions.includes(opt.value)
  );
  if (filteredOptions.length == 0) {
    return info;
  }

  return { ...info, options: filteredOptions };
}

function statesInCountry(country) {
  switch (country) {
    case "CA":
      return {
        options: ca_provinces,
        label: "Province",
        zip: "Postal Code"
      };

    case "US":
      return {
        options: us_states,
        label: "State",
        zip: "ZIP Code"
      };

    case "IT":
      return {
        options: it_provinces,
        label: "Province",
        zip: "Postal Code"
      };

    case "AU":
      return {
        options: au_states,
        label: "State",
        zip: "Postcode"
      };

    default:
      return {
        options: null,
        label: "State/Province (optional)",
        zip: "Postal Code"
      };
  }
}
