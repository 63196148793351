import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { div } from "tags";
import {
  initHostedFields,
  tokenizeHostedFields,
  teardownHostedFields
} from "./client";
import * as braintreeActions from "./actions";

export function submit() {
  return tokenizeHostedFields();
}

const styles = {
  input: {
    "font-size": "16px",
    "font-family": "helvetica, tahoma, calibri, sans-serif",
    color: "#3a3a3a"
  },
  ":focus": {
    color: "black"
  }
};

const fieldSpec = {
  //postalCode: { selector: "#cc-postal-code" },
  number: { selector: "#cc-number" },
  expirationDate: { selector: "#cc-expiry", placeholder: "MM/YY" },
  cvv: { selector: "#cc-cvv" }
};

class BraintreeFields extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      braintreeInit: PropTypes.func.isRequired,
      braintreeDeviceData: PropTypes.func.isRequired,
      braintreeCardType: PropTypes.func.isRequired,
      braintreeFieldValidity: PropTypes.func.isRequired,
      braintreeFieldPresence: PropTypes.func.isRequired,
      braintreeFieldTouched: PropTypes.func.isRequired
    }).isRequired,
    active: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.active) {
      this.activate();
    }
  }

  componentWillUnmount() {
    this.deactivate();
  }

  componentDidUpdate(prevProps) {
    if (this.props.active && !prevProps.active) {
      this.activate();
    } else if (!this.props.active && prevProps.active) {
      this.deactivate();
    }
  }

  activate() {
    const { actions } = this.props;

    initHostedFields(styles, fieldSpec).then(fields => {
      fields.on("empty", event => {
        actions.braintreeFieldPresence(event.emittedBy, false);
      });

      fields.on("notEmpty", event => {
        actions.braintreeFieldPresence(event.emittedBy, true);
      });

      fields.on("blur", event => {
        actions.braintreeFieldTouched(event.emittedBy);
      });

      fields.on("validityChange", event => {
        const field = event.fields[event.emittedBy];
        const grade = gradeField(field);
        actions.braintreeFieldValidity(event.emittedBy, grade);
      });

      fields.on("cardTypeChange", function(event) {
        const type = event.cards.length === 1 ? event.cards[0].type : null;
        actions.braintreeCardType(type);
      });
      actions.braintreeInit();
    });
  }

  deactivate() {
    teardownHostedFields();
  }

  render() {
    return div(".braintree", this.props.children);
  }
}

function gradeField(field) {
  if (field.isValid) {
    return "valid";
  } else if (field.isPotentiallyValid) {
    return "potentially-valid";
  } else {
    return "invalid";
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(braintreeActions, dispatch) };
}

export default connect(null, mapDispatchToProps)(BraintreeFields);
