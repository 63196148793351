import Immutable from "seamless-immutable";
import isNumber from "lodash/isNumber";
import { pluralize } from "h";
import { initCartCheckout } from "checkout/reducers/initCart";

export default function reducer(state = Immutable({}), action) {
  switch (action.type) {
    case "REFRESH_CHECKOUT_STATE":
      return initCartCheckout(action.payload.cart);
  }

  return state;
}

function calcPrimaryDomain(data) {
  const item = primaryCartItem(data);
  return item && item.name;
}

function primaryCartItem(data) {
  const index = data.primary_item;
  const cart = data.cart;
  const len = cart.length;
  if (isNumber(index) && 0 <= index && index < len) {
    return cart[index];
  }
}

function initCartItems(items) {
  return items.map(item =>
    Immutable({
      name: item.name,
      price: item.net_price,
      type: item.components[0].type,
      premium_type: item.components[0].premium_type,
      components: initComponents(item.components)
    })
  );
}

export function initComponents(components) {
  let subItems = [];
  const c = components[0];

  if (c.id === "domain") {
    subItems.push({
      label: c.summary,
      amount: c.net_amount,
      regular_amount: c.regular_net_amount
    });
  }

  subItems.push(...getMailboxItems(components.slice(1)));
  return subItems;
}

function getMailboxItems(components) {
  let items = [];
  components.forEach(c => {
    if (c.id === "instant_savings") {
      items.push({
        label: "Instant Savings",
        amount: c.amount
      });
    } else if (c.num_emails > 0) {
      items.push({
        label: showCountOfMailboxes(c.num_emails, c.mailbox_type),
        amount: c.amount
      });
    }
  });
  return items.filter(item => item.label);
}

function showCountOfMailboxes(count, type) {
  switch (type) {
    case "forward":
      return pluralize(count, "Mail Forward", "Mail Forwards");
    case "regular":
      return pluralize(count, "Small Mailbox", "Small Mailboxes");
    case "mailplus":
      return pluralize(count, "Big Mailbox", "Big Mailboxes");
    default:
      return null;
  }
}

function parseDollarAmount(str) {
  const num = str.replace(/[^\d\.\-]/g, "");
  return Number(num);
}

function initSummaryItems(cart) {
  let lines = [];

  if (cart.icann_fee && parseDollarAmount(cart.icann_fee) > 0) {
    lines.push({ label: icannFeeLabel(cart), amount: cart.icann_fee });
  }

  const discounts = cart.discounts || [];
  discounts.forEach(({ description, amount }) => {
    lines.push({ label: description, amount: amount });
  });

  if (cart.tax) {
    lines.push({
      label: `${cart.tax.type} (${cart.tax.rate * 100}%)`,
      amount: cart.tax.amount
    });
  }

  return lines;
}

function icannFeeLabel(cart) {
  const fees = cart.cart.map(item => item.components[0].icann_fee);
  const nonZeroFees = fees.filter(fee => fee && fee !== "$0.00");
  return nonZeroFees.length == 1 ? "ICANN Fee" : "ICANN Fees";
}
