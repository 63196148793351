export default function(paypalInstance, options, actions) {
  // Because PayPal tokenization opens a popup, this must be called
  // as a result of a user action, such as a button click.
  actions.paypalOpened();

  return new Promise((resolve, reject) => {
    paypalInstance.tokenize(
      {
        flow: "vault" // Required
        // Any other tokenization options
      },
      function(tokenizeErr, payload) {
        if (tokenizeErr) {
          if (tokenizeErr.code === "PAYPAL_POPUP_CLOSED") {
            actions.paypalCancelled();
          } else {
            actions.paypalError(tokenizeErr);
          }
        } else {
          actions
            .paypalCompleted(payload, options)
            .then(resolve)
            .catch(reject);
        }
      }
    );
  });
}
