const initialState = {
  skipSignin: false,
  formName: "create"
};

export default function accountFormReducer(state = initialState, action) {
  switch (action.type) {
    case "CONFIRM_SKIP_SIGNIN":
      return { skipSignin: "confirm" };

    case "CANCEL_SKIP_SIGNIN":
      return { skipSignin: false };

    case "TOGGLE_ACCOUNT_FORM":
      return {
        formName: state.formName === "create" ? "signin" : "create"
      }
  }

  return state;
}
