import { Promise } from "rsvp";
import doPaypal from "./paypal";
import client from "braintree-web/client";
import dataCollector from "braintree-web/data-collector";
import hostedFields from "braintree-web/hosted-fields";
import paypal from "braintree-web/paypal";
import applePay from "braintree-web/apple-pay";

let braintreeClient,
  hostedFieldsInstance,
  paypalInstance,
  applePayInstance,
  reduxActions;

const ApplePaySession = window.ApplePaySession;

export function isApplePaySupported() {
  if (window.cpProps && window.cpProps.testApplePay) {
    return true;
  }
  try {
    return ApplePaySession && ApplePaySession.canMakePayments();
  } catch (e) {
    return false;
  }
}

export function initBraintreeClient(clientID, actions) {
  if (braintreeClient) {
    return;
  }

  createBraintreeClient(clientID)
    .then(client => {
      braintreeClient = client;
      reduxActions = actions;
      actions.braintreeClientInitialized();
      return initDataCollector(client);
    })
    .then(collector => actions.braintreeDeviceData(collector.deviceData))
    .then(() => initPaypal(braintreeClient))
    .then(instance => {
      actions.paypalIsReady(instance);
      paypalInstance = instance;
    })
    .then(() => initApplePay(braintreeClient))
    .then(instance => {
      applePayInstance = instance;
    })
    .then(
      // tell tests that we're ready to interact
      () => document.body.setAttribute("data-braintree-ready", "true")
    )
    .catch(err => actions.braintreeError(err));
}

function createBraintreeClient(authorization) {
  return new Promise((resolve, reject) => {
    client.create({ authorization }, (err, client) => {
      if (err) {
        return reject({ error: err, context: "clientInstance" });
      } else {
        return resolve(client);
      }
    });
  });
}

function initDataCollector(client) {
  return new Promise((resolve, reject) => {
    const kount = true;
    dataCollector.create({ client, kount }, (err, collector) => {
      if (err) {
        return reject({ error: err, context: "dataCollector" });
      } else {
        return resolve(collector);
      }
    });
  });
}

function initPaypal(client) {
  return new Promise((resolve, reject) => {
    paypal.create({ client }, (error, instance) => {
      if (error) {
        return reject({ error: error, context: "paypal" });
      } else {
        return resolve(instance);
      }
    });
  });
}

function initApplePay(client) {
  return new Promise((resolve, reject) => {
    if (window.cpProps && window.cpProps.testApplePay) {
      return resolve({});
    }
    if (!ApplePaySession) {
      return reject("Apple Pay not available on this device!");
    }
    if (!ApplePaySession.canMakePayments()) {
      return reject("Apple Pay cannot make payments");
    }

    applePay.create({ client: client }, (err, instance) => {
      if (err) {
        return reject({ error: err, context: instance });
      } else {
        return resolve(instance);
      }
    });
  });
}

export function initHostedFields(styles, fields) {
  return new Promise((resolve, reject) => {
    const client = braintreeClient;
    hostedFields.create({ client, styles, fields }, (err, fields) => {
      if (err) {
        return reject({ error: err, context: "hostedFields" });
      } else {
        hostedFieldsInstance = fields;
        return resolve(fields);
      }
    });
  });
}

export function teardownHostedFields() {
  return new Promise((resolve, reject) => {
    if (hostedFieldsInstance) {
      hostedFieldsInstance.teardown(err => {
        hostedFieldsInstance = null;
        if (err) {
          return reject({ error: err, context: "teardown" });
        } else {
          return resolve();
        }
      });
    } else {
      resolve();
    }
  });
}

export function tokenizeHostedFields() {
  return new Promise(function(resolve, reject) {
    if (!hostedFieldsInstance) {
      resolve({});
    } else {
      hostedFieldsInstance.tokenize(function(err, payload) {
        if (err) {
          return reject({ error: err, context: "tokenizeHostedFields" });
        } else {
          return resolve(payload);
        }
      });
    }
  });
}

export function openPaypal(options) {
  if (!braintreeClient) {
    throw new Error("Braintree client not initialized");
  }

  return withPaypalInstance(paypal => doPaypal(paypal, options, reduxActions));
}

function withPaypalInstance(f) {
  if (paypalInstance) {
    return f(paypalInstance);
  } else {
    throw new Error("PayPal not ready");
  }
}

export function openApplePay(options) {
  const paymentRequest = applePayInstance.createPaymentRequest({
    total: {
      label: options.label || "Hover.com",
      amount: options.amount || options.cartTotal.replace("$", "")
    }
  });

  paymentRequest.requiredBillingContactFields = ["postalAddress"];

  const session = new ApplePaySession(1, paymentRequest);

  session.onvalidatemerchant = function(event) {
    applePayInstance.performValidation(
      {
        validationURL: event.validationURL,
        displayName: "Hover.com"
      },
      function(validationErr, merchantSession) {
        if (validationErr) {
          reduxActions.paypalError(
            "Could not validate merchant account: " + validationErr
          );
          session.abort();
          return;
        }
        session.completeMerchantValidation(merchantSession);
      }
    );
  };

  session.onpaymentauthorized = function(event) {
    applePayInstance.tokenize(
      {
        token: event.payment.token
      },
      function(tokenizeErr, payload) {
        if (tokenizeErr) {
          reduxActions.paypalError(
            "Could not get an Apple Pay token: " + tokenizeErr
          );
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          return;
        }
        session.completePayment(ApplePaySession.STATUS_SUCCESS);
        reduxActions.applePayCompleted(payload, {
          ...options,
          billingContact: event.payment.billingContact
        });
      }
    );
  };

  session.begin();
}
