import Immutable from "seamless-immutable";
import flow from "lodash/flow";
import { compose } from "redux";
import deepen from "../store/deepen";
import get from "lodash/get";
import { isApplePaySupported } from "../braintree";

const emptyObject = Immutable({});
const emptyArray = Immutable([]);

export function initCheckout(props) {
  return Immutable({
    supportOpen: props.supportOpen,
    model: initModel(props),
    isLoading: false,
    recaptchaKey: props.recaptchaKey,
    recaptchaSigninEnabled: props.recaptchaSigninEnabled,
    recaptchaSignupEnabled: props.recaptchaSignupEnabled,
    verificationRequired: props.verificationRequired,
    magicSigninEnabled: props.magicSigninEnabled
  });
}

function stepHandler(step, step_data) {
  switch (step) {
    case "account":
      return addAccount(step_data.account);

    case "contact":
      return addRegistration(step_data.contact, step_data.account);

    case "mailbox":
      return addMailbox(step_data.mailbox, step_data.account);

    case "billing":
      return addBilling(step_data.billing, step_data.contact || step_data.account);

    case "confirm":
      return addConfirm(step_data.confirm);

    default:
      if (step.startsWith("tld_")) {
        return addTldInfo(step, step_data[step]);
      }
  }
  throw new Error("Unknown step " + step);
}

function* createSteps({ steps, step_data }) {
  for (const step of steps) {
    yield stepHandler(step, step_data);
  }
}

export function initModel(props) {
  if (!props.steps) {
    return emptyObject;
  }
  const builder = flow([...createSteps(props), addStepNumbers()]);
  return builder(emptyObject);
}

export function skipStep() {
  return x => x;
}

export function addStep(stepData) {
  const item = Immutable(stepData).without("data");
  const data = Immutable(stepData.data || {});

  return compose(
    updateSteps(steps => [...steps, item]),
    addToFormData(stepData.type, data)
  );
}

function addToFormData(stepType, data) {
  return model => {
    if (stepType === "tld") {
      const old = get(model, "formData.tld", emptyObject);
      return model.setIn(["formData", "tld"], old.merge(data));
    } else {
      return model.setIn(["formData", stepType], data);
    }
  };
}

function updateSteps(f) {
  return model => model.set("steps", f(model.steps || emptyArray));
}

function addAccount(account) {
  if (account.status === "completed") {
    return addStep({
      name: "account",
      type: "account",
      status: "completed",
      data: { ...account.data, mode: "signed_in" },
      locked: true
    });
  } else if (account.status === "skipped") {
    return addStep({
      name: "account",
      type: "account",
      status: "completed",
      data: { skipped: true, mode: "signin" }
    });
  } else {
    const mode = (account.data && account.data.mode) || "create";
    return addStep({
      name: "account",
      type: "account",
      status: "pending",
      data: { ...account.data, mode, skipSignin: "no" }
    });
  }
}

function forceOption(listKey, key, data) {
  if (!data) {
    return {};
  }
  const options = data[listKey];
  if (options) {
    if (options.length === 1) {
      return { [key]: options[0] };
    }

    if (!options.includes(data[key])) {
      return { [key]: null };
    }
  }

  return {};
}

function addRegistration(contact, account) {
  const { data, ...registration } = registrationData(contact, account);
  const overrides = {
    ...forceOption("owner_countries", "country", data),
    ...forceOption("owner_states", "state", data),
    ...forceOption("owner_cities", "city", data)
  };

  return addStep({
    ...registration,
    data: { ...data, ...overrides }
  });
}

function registrationData(contact, account) {
  if (contact.status === "completed") {
    return {
      name: "registration",
      type: "registration",
      status: "completed",
      data: contact.data
    };
  } else if (account && account.status === "completed") {
    const { first_name, last_name, email } = account.data;
    return {
      name: "registration",
      type: "registration",
      status: "pending",
      data: { first_name, last_name, email, ...contact.data }
    };
  } else {
    return {
      name: "registration",
      type: "registration",
      status: "pending",
      data: contact.data
    };
  }
}

const cardOptions = {
  creditcard: {
    new: {
      value: "new",
      label: "Pay with credit card"
    },
    change: {
      value: "new",
      label: "Pay with a new credit card"
    },
    onfile: onFile => ({
      value: "creditcard",
      label: `Pay with card ending in ${onFile.last4}`
    })
  },
  paypal: {
    new: {
      value: "paypal",
      label: "Pay with PayPal"
    },
    change: {
      value: "paypal",
      label: "Pay with a different PayPal account"
    },
    onfile: onFile => ({
      value: "paypal_on_file",
      label: `Pay with PayPal ${onFile.ppe_email}`
    })
  },
  apple_pay: {
    new: {
      value: "apple_pay",
      label: "Pay with Apple Pay"
    },
    change: {
      value: "apple_pay",
      label: "Pay with Apple Pay using a different card"
    },
    onfile: onFile => ({
      value: "apple_pay_on_file",
      label: `Pay with Apple Pay ${onFile.description}`
    })
  }
};

function genPaymentOptions(pay_mode) {
  switch (pay_mode) {
    case "creditcard":
      return [
        "creditcard onfile",
        "creditcard change",
        "paypal new",
        "apple_pay new"
      ];

    case "paypal":
      return ["paypal onfile", "creditcard new", "paypal change", "apple_pay new"];

    case "apple_pay":
      return [
        "apple_pay onfile",
        "creditcard new",
        "paypal new",
        "apple_pay change"
      ];

    default:
      return ["creditcard new", "paypal new", "apple_pay new"];
  }
}

export function paymentOptions(data) {
  const applePaySupported = isApplePaySupported();
  const payment_on_file = data.payment_on_file;
  const onfile = payment_on_file && payment_on_file.pay_mode;

  const opts = genPaymentOptions(onfile)
    .map(name => {
      const [type, action] = name.split(" ");
      if (!applePaySupported && type === "apple_pay" && action !== "onfile") {
        return null;
      } else if (action === "onfile") {
        return cardOptions[type][action](payment_on_file);
      } else {
        return cardOptions[type][action];
      }
    })
    .filter(opt => !!opt);

  const payMethod = data.pay_method;
  const availablePayMethods = opts.map(opt => opt.value);
  if (availablePayMethods.includes(payMethod)) {
    return { paymentOptions: opts, payMethod };
  } else {
    return { paymentOptions: opts, payMethod: availablePayMethods[0] };
  }
}

function addPaymentOptions(data) {
  const opts = paymentOptions(data);
  return data.merge({
    paymentOptions: opts.paymentOptions,
    pay_method: opts.payMethod,
    ...cardOnFile(data)
  });
}

function cardOnFile(data) {
  const payment = data.payment_on_file;
  if (payment && payment.pay_mode === "creditcard") {
    return {
      cc_full_name: payment.name_on_card,
      cc_card_number: payment.card_number,
      cc_expiry_month: payment.expiry_month,
      cc_expiry_year: payment.expiry_year
    };
  }
  return {};
}

function addBilling(billing, registration) {
  if (billing.status === "not_required") {
    return skipStep();
  }
  else if (billing.status === "completed") {
    return addStep({
      name: "billing",
      type: "billing",
      status: "completed",
      data: addPaymentOptions(Immutable(billing.data))
    });
  } else if (registration && registration.status === "completed") {
    const contact = registration.data;
    const fullName = [contact.first_name, contact.last_name].join(" ");
    const overrides = {
      full_name: fullName,
      new_full_name: fullName,
      pay_method: "new",
      ...billing.data
    };
    const defaultBillingData = Immutable(contact).merge(overrides);
    return addStep({
      name: "billing",
      type: "billing",
      status: "pending",
      data: addPaymentOptions(defaultBillingData)
    });
  } else {
    return addStep({
      name: "billing",
      type: "billing",
      status: "pending",
      data: addPaymentOptions(Immutable(billing.data || {}))
    });
  }
}

function addTldInfo(stepName, tldInfo) {
  return addStep({
    name: stepName,
    type: "tld",
    status: tldInfo.status,
    meta: {
      domain_names: tldInfo.domain_names,
      params: tldInfo.params,
      values: tldInfo.values,
      restrictions: tldInfo.restrictions
    },
    data: deepen(tldInfo.values)
  });
}

function addMailbox(mailboxInfo) {
  return addStep({
    name: "mailbox",
    type: "mailbox",
    status: mailboxInfo.status,
    data: mailboxInfo
  });
}

function addConfirm(data) {
  return addStep({
    name: "confirm",
    type: "confirm",
    status: "pending",
    data: {
      termsChecked: false,
      ...data
    }
  });
}

function addStepNumbers() {
  return updateSteps(steps => {
    return steps.map((step, k) => step.set("position", k + 1));
  });
}
