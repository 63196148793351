import { Component } from "react";
import { div } from "tags";

class PaypalButton extends Component {
  componentDidMount() {
    if (!this.elt) return;
    const g = document.createElement("script");
    g.src = "https://www.paypalobjects.com/api/button.js";
    g.setAttribute("data-merchant", "braintree");
    g.setAttribute("data-id", "paypal-button");
    g.setAttribute("data-button", "checkout");
    g.setAttribute("data-color", "blue");
    g.setAttribute("data-size", "medium");
    g.setAttribute("data-shape", "pill");
    g.setAttribute("data-button_type", "button");
    g.setAttribute("data-button_disabled", "false");
    g.addEventListener("load", () => {
      const button = this.elt.querySelector("button");
      button.disabled = this.props.disabled;
      button.addEventListener("click", e => this.props.onClick(e));
    });
    this.elt.appendChild(g);
  }

  componentDidUpdate() {
    if (!this.elt) return;
    const button = this.elt.querySelector("button");
    if (!button) return;
    button.disabled = this.props.disabled;
  }

  componentWillUnmount() {
    this.elt.innerHTML = "";
  }

  render() {
    return div(".btn-paypal", {
      onSubmit: e => {
        e.preventDefault();
      },
      ref: e => (this.elt = e)
    });
  }
}

export default PaypalButton;
