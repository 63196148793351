import * as Sentry from "@sentry/react";

/**
 * @see https://docs.sentry.io/platforms/javascript/guides/react/usage/
 * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/globalhandlers/
 *   Configuration for global handlers for page errors and also unhandled
 *   promise rejections -- I think we have a lot of promises without their
 *   corresponding #catch
 */
Sentry.init({
  allowUrls: [/www\.hover\.com/i],
  dsn: "https://b4d847ce9f6c4e638ab5ffca2bd6a930@o76234.ingest.us.sentry.io/164872",
  enabled: window.SENTRY_ENABLED,
  maxBreadcrumbs: 50,
  normalizeDepth: 10,
  replaysSessionSampleRate: 0.05,
  replaysOnErrorSampleRate: 0.5,
  tracesSampleRate: 0.05,
  onunhandledrejection: false,

  initialScope: function(scope) {
    if (window.USERNAME) {
      scope.setUser({ username: window.USERNAME });
    }

    if (window.AUSERNAME) {
      scope.setContext("admin", {username: window.AUSERNAME});
    }

    return scope;
  },
});
