const initialState = {
  terms: ""
};

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case "CIRA_RECEIVED_TERMS":
      return { ...state, terms: payload.html };
  }
  return state;
}
