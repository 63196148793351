import mailchimpLogo from "./mailchimpLogo.png";
import h from "h";

class MailchimpConsentContent {
  explainer() {
    return [
      "In order to send newsletters, we use MailChimp. ",
      "If you continue, your ",
      h("b", "email address"),
      " will be shared with them."
    ];
  }

  label() {
    return "Allow Hover to share my personal information with MailChimp";
  }

  img() {
    return h("img", {
      src: mailchimpLogo,
      width: 100
    });
  }

  fields() {
    return {
      email_notifications: {
        newsletters: true,
        consent: true,
        tag: "settings"
      }
    };
  }
}

export default MailchimpConsentContent;
