import { combineReducers } from "redux";

import checkout from "./checkoutReducer";
import cart from "./cartReducer";
import signin from "./signinReducer";
import accountForm from "./accountFormReducer";
import cira from "./ciraReducer";
import braintree from "../braintree/reducer";

import * as braintreeQueries from "../braintree/reducer";
import * as checkoutQueries from "./checkoutReducer";
import * as signinQueries from "./signinReducer";

export default combineReducers({
  accountForm,
  cart,
  checkout,
  cira,
  signin,
  braintree
});

export function braintreeProxyFields(state) {
  return braintreeQueries.proxyFields(state.braintree);
}

export function braintreeDeviceDataCollected(state) {
  return braintreeQueries.deviceData(state.braintree);
}

export function billingData(state) {
  return checkoutQueries.billingData(state.checkout);
}

export function formData(state, key) {
  return checkoutQueries.formData(state.checkout, key);
}

export function isSignedIn(state) {
  return checkoutQueries.isSignedIn(state.checkout);
}

export function numSigninFailures(state) {
  return signinQueries.numFailures(state.signin);
}
