import React, { Component } from "react";
import Immutable from "immutable";

import { h, fasIcon } from "h";
import { priceSpans } from "./price";
import c from "classnames";
import Tooltip from "cp/tooltip/Tooltip";

import PropTypes from "prop-types";

import throttle from "raf-throttle";

class SlideCategory extends Component {
  renderBundleMatches() {
    const { groupedResults, categoryId, matches, sendAction } = this.props;
    if (
      categoryId === "exact" &&
      Array.isArray(groupedResults.top) &&
      groupedResults.top.length > 0
    ) {
      const onClick = () =>
        sendAction({
          name: "addToCart",
          items: groupedResults.top.map(r => r.domain),
          categoryName: "bundle"
        });

      const bundleRow = d => {
        const children = [
          h(".name", [h("p.bold", d.domain), ...renderTagline(d)]),
          h(".price", priceSpans(d)),
          h(
            ".cta",
            h(AddToCartButton, { ...d, sendAction, loading: this.props.loading })
          )
        ];
        return h(
          InnerRow,
          {
            key: "bundle-" + d.domain,
            className: "bundle__row row"
          },
          children
        );
      };
      return h(".bundle", [
        h(".bundle__header", [
          h("p.bold", "Here are other great domain matches"),
          h("button.btn-link.bold", { onClick }, "Add All To Cart")
        ]),
        groupedResults.top.map(bundleRow)
      ]);
    }
  }
  render() {
    const {
      cart,
      heading,
      matches,
      categoryId,
      filters,
      progress,
      sendAction,
      integration,
      hl
    } = this.props;
    if (this.props.groupedResults.exact && categoryId === "top") return null;
    const filterSet = Immutable.Set(filters);
    const { makeOffers, domainAgents, sedoPartnerUrl } = progress;
    const isExact = categoryId == "exact";

    const hidden = categoryHidden(categoryId);
    const categoryName = /\D/.test(categoryId) ? categoryId : null;
    const filterMakeOffers = match => {
      if (match.result_type !== "premium_make_offer") {
        return true;
      } else {
        return match.is_exact;
      }
    };
    const resultRows = overrideFreeDomains(matches, cart)
      .filter(filterMakeOffers)
      .map(match =>
        renderMatch(
          match,
          categoryName || match.search_category,
          domainAgents,
          makeOffers,
          this.props.loading
        )
      );
    const styles = hidden
      ? { opacity: 0, maxHeight: 0, marginBottom: 0 }
      : {
          opacity: 1,
          maxHeight: this.props.metrics.height,
          marginBottom: 20
        };

    const tableProps = {
      key: categoryId,
      "data-id": categoryId,
      // className: hl ? "highlighted" : null
      className: c({ highlighted: hl, exact: isExact })
    };

    if (resultRows.filter(Boolean).length == 0) return null;

    return h(
      "div.catslide",
      { ref: e => (this.elt = e), key: categoryId, style: styles },
      [
        h(".category", tableProps, [
          h(".heading", [h("h2", heading)]),
          h(".rows", resultRows)
        ]),
        this.renderBundleMatches()
      ]
    );

    function overrideFreeDomains(results, cart) {
      if (cart.voucherEligible == 0) {
        return results;
      }

      return results.map(r => {
        if (r.partner_promotion) return { ...r, price: r.regular_price };
        else return r;
      });
    }

    function offerRecap() {
      return h(
        ".row.row-recap",
        h(".row-lined", h(".row-inner", h(".col.recap", integration.offer)))
      );
    }

    function renderMatch(match, categoryName, domainAgents, makeOffers, loading) {
      if (match.result_type == "premium_make_offer") {
        return h(MakeOfferDomain, {
          sendAction,
          match,
          categoryName,
          makeOffers
        });
      } else if (match.status === "taken") {
        return h(TakenDomain, {
          sendAction: sendAction,
          domain: match.domain,
          domainAgents: domainAgents
        });
      } else {
        return h(DomainMatch, {
          sendAction: sendAction,
          categoryName,
          ...match,
          loading
        });
      }
    }

    function categoryHidden(id) {
      return !filterSet.has(id) && !filterSet.has("all");
    }
  }
}

class CallUs extends Component {
  componentDidMount() {
    window.$(this.tip).qtip({
      content: {
        text:
          "Interested in this domain? Give us a call and our sales team can help you out.<br>\n1-866-731-6556"
      },
      position: { my: "left middle", at: "right middle" },
      style: { classes: "hover" },
      events: { show: this.onShow }
    });
  }

  render() {
    return h(
      "div.call_us",
      { ref: e => (this.tip = e), title: "Call us" },
      fasIcon("phone")
    );
  }
}

class AddToCartButton extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
  }
  setHoverState(hover) {
    this.setState({ hover });
  }

  renderIcon() {
    if (this.props.loading && this.props.loading[this.props.domain])
      return fasIcon("spinner.fa-spin");
    if (this.props.inCart) {
      return this.state.hover ? fasIcon("times") : fasIcon("check");
    } else {
      return fasIcon("plus");
    }
  }
  render() {
    if (this.props.expensive) {
      return h(CallUs);
    } else {
      const action = this.props.inCart ? "removeFromCart" : "addToCart";
      const title = this.props.inCart ? "Remove from cart" : "Add to cart";
      const isExact = this.props.categoryName == "exact";
      const text = isExact ? "Add To Cart" : "";
      const { domain, categoryName, sendAction } = this.props;
      const className = c("add_to_cart", "add-cart", { inCart: this.props.inCart });
      const icon = this.renderIcon();
      return h(
        "button",
        {
          className,
          title,
          onClick: () => sendAction({ name: action, items: [domain], categoryName }),
          onMouseEnter: () => this.setHoverState(true),
          onMouseLeave: () => this.setHoverState(false)
        },
        icon
      );
    }
  }
}

class ExactAddToCartButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  onMouseEnter() {
    this.setState({ hover: true });
  }

  onMouseLeave() {
    this.setState({ hover: false });
  }
  buttonText() {
    const text = this.props.inCart ? "Remove from cart" : "Add to cart";
    return h("span", text);
  }

  icon() {
    if (this.props.loading && this.props.loading[this.props.domain])
      return fasIcon("spinner.fa-spin");
    if (!this.props.inCart) return fasIcon("plus");

    return this.state.hover ? fasIcon("times") : fasIcon("check");
  }
  render() {
    if (this.props.expensive) {
      return h(CallUs);
    } else {
      const action = this.props.inCart ? "removeFromCart" : "addToCart";
      const text = this.buttonText();
      const icon = this.icon();
      const { domain, categoryName, inCart, sendAction } = this.props;
      return h(
        "button.btn.submit.primary.exact-add-cart.add-cart",
        {
          onClick: () => sendAction({ name: action, items: [domain], categoryName }),
          onMouseEnter: () => this.onMouseEnter(),
          onMouseLeave: () => this.onMouseLeave()
        },
        [icon, text]
      );
    }
  }
}

class DomainMatch extends Component {
  render() {
    const { domain, inCart, categoryName } = this.props;
    const [tagline] = this.renderTagLine();
    const free = isFree(this.props) && categoryName !== "Suggestions";
    const isExact = categoryName === "exact";
    const nameText = isExact
      ? [
          h("span", " is available. "),
          h("span.no-mobile", "Get it for your website!")
        ]
      : [""];
    const className = c("row", { free, inCart });

    const rowProps = {
      name: [h("span.bold", domain), ...nameText, tagline],
      price: priceSpans(this.props),
      cta: this.renderButton(),
      className
    };
    const exactRowProps = {
      ...rowProps,
      className: rowProps.className.replace("row", "")
    };
    return isExact ? ExactMatch(exactRowProps) : Row(rowProps);
  }

  renderTagLine() {
    const props = this.props;
    if (props.is_premium) {
      const isRegistryPremium = props.premium_type === "tiered";
      const domainType = showDomainType(isRegistryPremium);
      const renewalPrice = ` Annual renewal is ${props.renew_price}.`;
      return [
        h(".tagline", [
          isRegistryPremium ? h(TieredPriceStar) : h(PremiumStar),
          domainType,
          renewalPrice
        ])
      ];
    } else if (props.tagline) {
      return [h(".tagline", props.tagline)];
    } else if (props.categoryName == "exact" && props.renew_price) {
      const renewalPrice = ` Annual renewal is ${props.renew_price}.`;
      return [h(".tagline", renewalPrice)];
    } else {
      return [];
    }
  }

  renderButton() {
    return this.props.categoryName !== "exact"
      ? h(AddToCartButton, this.props)
      : h(ExactAddToCartButton, this.props);
  }
}

function renderTagline(props) {
  if (props.is_premium) {
    const isRegistryPremium = props.premium_type === "tiered";
    const domainType = showDomainType(isRegistryPremium);
    const renewalPrice = ` Annual renewal is ${props.renew_price}.`;
    return [
      h(".tagline", [
        isRegistryPremium ? h(TieredPriceStar) : h(PremiumStar),
        domainType,
        renewalPrice
      ])
    ];
  } else if (props.tagline) {
    return [h(".tagline", props.tagline)];
  } else if (props.categoryName == "exact" && props.renew_price) {
    const renewalPrice = ` Annual renewal is ${props.renew_price}.`;
    return [h(".tagline", renewalPrice)];
  } else {
    return [];
  }
}

function isFree({ price, voucher_price }) {
  return (
    price === "$0.00" ||
    price === "$0" ||
    voucher_price === "$0.00" ||
    voucher_price === "$0"
  );
}

function showDomainType(isRegistryPremium) {
  if (isRegistryPremium) {
    return h("span", ["This is a tiered price domain."]);
  } else {
    return "This is a premium domain.";
  }
}

function MakeOfferDomain({ match, sendAction, categoryName, makeOffers }) {
  const domain = match.domain;
  const isExact = categoryName == "exact";
  const className = c({
    btn: isExact,
    submit: isExact,
    primary: isExact,
    "btn-link": !isExact,
    "make-offer": makeOffers
  });

  const offer = makeOffers
    ? h(
        "button",
        { className, onClick: () => sendAction({ name: "openSedoDialog", domain }) },
        "Make an offer"
      )
    : h(".taken", "Not available");
  const nameText =
    makeOffers && isExact && h("span", " is for sale. Buy with Sedo.com ");

  const rowProps = {
    name: [h("span.bold", domain), nameText],
    price: "",
    cta: offer,
    className: c("row", "make-offer")
  };

  return isExact ? ExactMatch(rowProps) : Row(rowProps);
}

MakeOfferDomain.propTypes = {
  match: PropTypes.object.isRequired,
  sendAction: PropTypes.func.isRequired,
  categoryName: PropTypes.string,
  makeOffers: PropTypes.bool
};

class TakenDomain extends Component {
  static propTypes = {
    domain: PropTypes.string.isRequired,
    domainAgents: PropTypes.bool.isRequired,
    sendAction: PropTypes.func.isRequired
  };

  render() {
    const { domain, domainAgents } = this.props;

    const nameText = domainAgents
      ? [
          h("span", " is taken. "),
          h("span.no-mobile", "Domain Agents might be able to help.")
        ]
      : [
          h("span", " is taken. "),
          h("span.no-mobile", "Try another search or check the suggestions below.")
        ];
    const name = [h("span.bold", domain), ...nameText];
    const cta = domainAgents
      ? h(
          "button.btn.submit.primary.make-offer",
          { onClick: () => this.onClick() },
          "Make an offer"
        )
      : h(".taken", "Not available");

    const price = "";

    return ExactMatch({ name, price, cta, className: "make-offer" });
  }

  onClick() {
    const { domain } = this.props;
    this.props.sendAction({ name: "openOfferDialog", domain });
  }
}

function Row({ name, price, cta, className, innerClass = "row__inner" }) {
  return h("div", { className }, [
    h(InnerRow, { className: innerClass }, [
      h(".name", name),
      h(".price", price),
      h(".cta", cta)
    ])
  ]);
}

function InnerRow({ children, className }) {
  return h("div", { className }, children);
}

function ExactMatch({ name, price, cta, className = "" }) {
  return h(".exact-match", { className }, [
    h(".exact-match__inner", [
      h(".name", name),
      h(".grouped_price_add", [h(".price", price), h(".cta", cta)])
    ])
  ]);
}

function PremiumStar({ domain }) {
  return h(
    Tooltip,
    {
      id: domain + "-premium-star",
      text: "This is a premium domain. Click the star to learn more.",
    },
    h(
      "a.premium_star.star",
      {
        href: "https://help.hover.com/hc/en-us/articles/217281497",
      },
      fasIcon("star")
    )
  );
}

export class TieredPriceStar extends Component {
  render() {
    return h("a.tiered_price_star.star", fasIcon("star"));
  }
}

const measurements = e => ({
  height: e.scrollHeight
});

const withMeasurements = Inner =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount() {
      this.eventHandler = throttle(() => this.updateMetrics());
      window.addEventListener("scroll", this.eventHandler);
      window.addEventListener("resize", this.eventHandler);
      this.eventHandler();
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.eventHandler);
      window.removeEventListener("resize", this.eventHandler);
    }

    updateMetrics() {
      if (this.elt) {
        if (this.elt.scrollHeight < 400) {
          window.eee = this.elt;
        }
        this.setState(measurements(this.elt));
      }
    }

    render() {
      return h(Inner, { ...this.props, metrics: this.state }, [
        h("div", { ref: e => (this.elt = e) }, this.props.children)
      ]);
    }
  };

export default withMeasurements(SlideCategory);
