export const braintreeInit = () => ({ type: "BRAINTREE_INIT" });
export const braintreeClientInitialized = () => ({
  type: "BRAINTREE_CLIENT_INITED"
});
export const braintreeDeviceData = deviceData => ({
  type: "BRAINTREE_DEVICE_DATA",
  deviceData
});
export const braintreeFieldValidity = (name, grade) => ({
  type: "BRAINTREE_FIELD_VALIDITY",
  name,
  grade
});
export const braintreeFieldPresence = (name, isPresent) => ({
  type: "BRAINTREE_FIELD_PRESENCE",
  name,
  isPresent
});
export const braintreeFieldTouched = name => ({
  type: "BRAINTREE_FIELD_TOUCHED",
  name
});
export const braintreeCardType = cardType => ({
  type: "BRAINTREE_CARD_TYPE",
  cardType
});
export const braintreeError = err => {
  if (err instanceof Error) {
    return { type: "BRAINTREE_ERROR", message: err.toString() };
  } else {
    return {
      type: "BRAINTREE_ERROR",
      message: err.error,
      operation: err.context
    };
  }
};

export const paypalIsReady = () => ({ type: "PP_READY" });
export const openPaypal = options => ({
  type: "PP_OPEN",
  options,
  remote: true
});
export const paypalOpened = () => ({ type: "PP_OPENED" });
export const paypalCompleted = (payload, options) => ({
  type: "PP_COMPLETED",
  payload,
  options,
  remote: true
});
export const paypalCancelled = () => ({ type: "PP_CANCELLED" });
export const paypalError = error => ({ type: "PP_ERROR", error });
export const paypalLogo = html => ({ type: "PP_LOGO", html });
export const paypalClosed = () => ({ type: "PP_CLOSED" });

export const openApplePay = options => ({
  type: "AP_OPEN",
  options,
  remote: true
});
export const applePayCompleted = (payload, options) => ({
  type: "AP_COMPLETED",
  payload,
  options,
  remote: true
});
export const applePayError = error => ({ type: "AP_ERROR", error });
