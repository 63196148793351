import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import { fasIcon } from "h";
import {
  form,
  ManagedForm,
  TextField,
  ToggleField,
  SubmitButton,
} from "checkout/form";
import NativeCheckbox from "checkout/form/NativeCheckbox";
import ErrorContainer from "checkout/form/ErrorContainer";
import validator from "../../../checkout/util/validator";
import MailchimpConsentContent from "../notifications/MailchimpConsentContent";
import ThirdPartyConsentItem from "../notifications/ThirdPartyConsentItem";
import "../../styles/email-form.sass";

function EmailForm(props) {
  const {
    form,
    fields,
    onCancel,
    onBack,
    emailVerificationEnabled,
    hideHeader,
    initialValues
  } = props;
  const onSubmit = (values) => {
    const email_notifications = {
      newsletters: values.newsletters,
      newsletters_consent: values.newsletters ? values.newsletters_consent : false,
    };
    const fields =
      initialValues.email !== values.email
        ? { email: values.email, email_notifications }
        : { email_notifications };
    props.onSubmit(fields);
  };

  const submitText = emailVerificationEnabled ? "Continue" : "Save Changes";

  const showConsent = (notificationName) =>
    fields[notificationName].checked &&
    (!initialValues[notificationName] || fields.email.value !== initialValues.email);

  const onChange = (e) => {
    const email = e.target.value;
    if (email !== initialValues.email) {
      form.mergeCurrentValues({
        email,
        newsletters: false,
        newsletters_consent: false,
      });
    }
  };

  const customHandlers = {
    onChange,
  };

  return (
    <ManagedForm form={form} className={"settings-email-form"} onSubmit={onSubmit}>
      <div className="modal-scrollable">
        {!hideHeader && (
          <div className="modal-header">
            <h2 className="modal-title">Edit Email</h2>
          </div>
        )}
        <div className="modal-body">
          <div className="row">
            <TextField
              width={12}
              label={"Email"}
              field={fields.email}
              autoFocus={true}
              customHandlers={customHandlers}
            />
          </div>
          <p className="explainer">
            {[
              "Your old email address will be ",
              "unsubscribed from notifications. ",
              "These notification preferences will be used ",
              "with your new email address:",
            ]}
          </p>
          <ul className="attribute-list">
            <ToggleItem label="Newletters" field={fields.newsletters} />
            {showConsent("newsletters") && (
              <ThirdPartyConsentItem
                field={fields.newsletters_consent}
                content={new MailchimpConsentContent()}
              />
            )}
          </ul>
        </div>
      </div>
      <div className="modal-footer">
        {onBack && (
          <div className="delete-button">
            <button
              type="button"
              onClick={onBack}
              className="btn-link btn-caps"
            >{fasIcon("chevron-left")} Back</button>
          </div>
        )}
        {onCancel && (
          <button
            type="button"
            onClick={onCancel}
            className="btn-link left-button btn-caps"
          >
            Cancel
          </button>
        )}
        <SubmitButton form={form} text={submitText} />
      </div>
    </ManagedForm>
  );
}

EmailForm.propTypes = {
  form: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onCancel: requiredFunction("onBack"),
  onBack: requiredFunction("onCancel"),
  emailVerificationEnabled: PropTypes.bool.isRequired,
  hideHeader: PropTypes.bool,
};

function ToggleItem({ label, field }) {
  const className = c("attribute-list__item", {
    [field.checked]: "no-bottom-divider",
  });
  return (
    <li className={className}>
      <div className="attribute-list__name">{label}</div>
      <div className="attribute-list__value">
        <ToggleField field={field} />
      </div>
    </li>
  );
}

function ConsentItem({ field, content }) {
  return (
    <ErrorContainer
      className="third-party-consent"
      field={field}
      data-testid={`${field.name}-ci`}
    >
      <div className="consent-row">
        <div className="consent-logo">{content.img()}</div>
        <div className="consent-rhs">
          <div className="consent-explainer">{content.explainer()}</div>
          <label htmlFor={field.name} className="consent-checkbox">
            <NativeCheckbox field={field} />
            <span>{content.label()}</span>
          </label>
        </div>
      </div>
    </ErrorContainer>
  );
}

const fields = [
  "email",
  "newsletters",
  "newsletters_consent",
];

function validate(values) {
  return validator(values, {
    email: {
      presence: true,
      email: true,
    },
    newsletters_consent: {
      checked: values.newsletters,
    },
  });
}

function requiredFunction(dependent) {
  return (props, propName, _componentName) => {
    if (
      !props[dependent] &&
      (props[propName] == undefined || typeof props[propName] != "function")
    ) {
      return new Error("Please provide a onCancel function!");
    }
  };
}

export default form(fields, validate)(EmailForm);
