import Immutable from "seamless-immutable";

export function initBraintree({ oldState, opts = {} }) {
  if (!oldState) {
    oldState = Immutable({
      clientIsValid: false,
      needHostedFields: false,
      deviceData: null,
      ...opts
    });
  }
  return oldState.merge({
    validity: {
      number: "potentially-valid",
      expirationDate: "potentially-valid",
      cvv: "potentially-valid"
    },
    presence: {},
    touched: {},
    cardType: null
  });
}

export function isValid(state) {
  return (
    state.validity.number === "valid" &&
    state.validity.expirationDate === "valid" &&
    state.validity.cvv === "valid"
  );
}

export function proxyFields(state) {
  return {
    number: validationProxy("number", state),
    expirationDate: validationProxy("expirationDate", state),
    cvv: validationProxy("cvv", state)
  };
}

export function deviceData(state) {
  return state.deviceData;
}

export function clientIsValid(state) {
  return state.clientIsValid;
}

function validationProxy(field, state) {
  const { validity, presence } = state;
  if (!presence[field]) {
    return "";
  } else if (validity[field] === "valid") {
    return "valid";
  } else {
    return "invalid";
  }
}

export default function reducer(state = {}, action) {
  switch (action.type) {
    case "BRAINTREE_INIT":
      return initBraintree({ oldState: state });

    case "BRAINTREE_CLIENT_INITED":
      return state.set("clientIsValid", true);

    case "ENTERED_STEP":
      return state.set("needHostedFields", action.payload === "billing");

    case "BRAINTREE_DEVICE_DATA":
      return state.set("deviceData", action.deviceData);

    case "BRAINTREE_FIELD_VALIDITY":
      return state.setIn(["validity", action.name], action.grade);

    case "BRAINTREE_FIELD_PRESENCE":
      return state.setIn(["presence", action.name], action.isPresent);

    case "BRAINTREE_FIELD_TOUCHED":
      return state.setIn(["touched", action.name], true);

    case "BRAINTREE_CARD_TYPE":
      return state.set("cardType", action.cardType);

    case "PP_READY":
      return state.set("paypalIsReady", true);

    case "PP_OPENED":
      return state.set("paypalIsOpen", true);

    case "PP_CLOSED":
      return state.set("paypalIsOpen", false);

    case "PP_COMPLETED":
      return state.set("paypal", action.payload).set("paypalIsOpen", false);

    case "PP_CANCELLED":
      return state.set("paypalIsOpen", false);

    case "PP_LOGO":
      return state.set("paypalLogo", action.html);
  }

  return state;
}
