import compose from "lodash/flowRight";
import Immutable from "seamless-immutable";

const initialState = Immutable({
  step: "ask_username_password",
});

export function initSignin(props) {
  let state = initialState;
  if (props.step) {
    state = pushStep(props.step)(state);
  }
  return state.merge(props);
}

export function numFailures(state) {
  return state.failures || 0;
}

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "SIGNIN_REQUEST":
      return compose(setProp("remember", action.remember), waiting)(state);

    case "SIGNIN_RESPONSE":
      return compose(nowaiting, updateSigninResponse(payload))(state);

    case "AUTH_2FA_REQUEST":
      return waiting(state);

    case "AUTH_2FA_RESPONSE":
      return compose(nowaiting, update2faResponse(action.data))(state);

    case "RESEND_SMS":
      return startResendSms()(state);

    case "RESEND_SMS_CANCEL":
      return state.without("resendConfirm");

    case "RESEND_SMS_REQUEST":
      return waiting(state);

    case "RESEND_SMS_RESPONSE":
      return compose(
        nowaiting,
        (s) => s.without("resendConfirm"),
        updateResendSmsResponse(action.data)
      )(state);

    case "2FA_RECOVERY":
      return compose(pushStep("recovery"))(state);

    case "2FA_RECOVERY_CANCEL":
      return popStep()(state);

    case "2FA_RECOVERY_REQUEST":
      return waiting(state);

    case "2FA_RECOVERY_RESPONSE":
      return compose(nowaiting, update2faRecoveryResponse(action.data))(state);

    case "FORGOT_USERNAME":
      return pushStep("forgot_username")(state);

    case "FORGOT_PASSWORD":
      return pushStep("forgot_password")(state);

    case "SUBMIT_USERNAME_RECOVERY":
      return waiting(state);

    case "SUBMIT_PASSWORD_RECOVERY":
      return waiting(state);

    case "PASSWORD_RECOVERY_RESPONSE":
      return compose(
        nowaiting,
        updatePasswordRecoveryResponse(action.response)
      )(state);

    case "SIGNIN_REMOTE_ERROR":
      return nowaiting(state);

    case "SIGNIN_CANCEL":
      return compose(popStep(), deleteProp("note"))(state);

    case "INIT_TOKEN_REQUEST":
      return compose(
        setProp("email", action.email),
        setProp("remember", action.remember)
      )(state);

    case "INIT_TOKEN_RESPONSE": {
      return pushStep("ask_token")(state);
    }

    case "SUBMIT_TOKEN_REQUEST":
      return setProp("token", action.token)(state);

    case "SIGNIN_GO_TO_STEP": {
      return pushStep(action.step)(state);
    }

    case "SET_TOKEN_ERROR": {
      return setProp("tokenError", action.errors._error)(state);
    }

    case "CLEAR_TOKEN_ERROR": {
      return deleteProp("tokenError")(state);
    }
    case "SIGNIN_EMAIL_CONFIRMATION": {
      return compose(
        pushStep("ask_email_confirmation"),
        setProp("verification", action.payload)
      )(state);
    }

    case "SIGNIN_EDIT_EMAIL":
      return pushStep("edit_email")(state);

    case "SIGNIN_SUBMIT_EMAIL": {
      return compose(
        showCodeVerification,
        setProp("verification", { ...state.verification, ...action.payload})
      )(state);
    }

    case "SIGNIN_CODE_VERIFICATION":
      return showCodeVerification(state);

    case "SIGNIN_GO_BACK":
      return popStep()(state);

    case "SIGNIN_SET_VERIFICATON_ERROR":
      return setProp("verification", { ...state.verification, ...action.payload})(state);

    default:
      return state;
  }
}

function completed() {
  return Immutable({ step: "completed" });
}

function updateSigninResponse(payload) {
  return (state) => {
    switch (payload.status) {
      case "completed":
        return state.without("failures");

      case "need_2fa": {
        const { type, mobile, email } = payload;
        const nextStep = `ask_2fa_${type}`;
        const newState = {};
        if (type == "sms") {
          newState.mobile = mobile;
        } else if (type == "email") {
          newState.email = email;
        }
        return compose(pushStep(nextStep), mergeProps(newState))(state);
      }

      case "ask_user_select":
        return compose(
          pushStep(payload.status),
          setProp("users", payload.users)
        )(state);

      default:
        return state
          .set("error", payload.error)
          .set("failures", numFailures(state) + 1);
    }
  };
}

function update2faResponse({ succeeded, error }) {
  return (state) => {
    if (succeeded) {
      return completed();
    } else {
      return state.set("error", error);
    }
  };
}

function update2faRecoveryResponse({ succeeded, error }) {
  return (state) => {
    if (succeeded) {
      return completed();
    } else {
      return state.set("error", error);
    }
  };
}

function updatePasswordRecoveryResponse({ succeeded, error }) {
  if (succeeded) {
    return compose(nowaiting, popStep(), setProp("note", "Email sent."));
  } else {
    return compose(nowaiting, (state) => state.set("error", error));
  }
}

function startResendSms() {
  return (state) => {
    return state.without("note").without("error").set("resendConfirm", "asking");
  };
}

function updateResendSmsResponse({ succeeded, note, error }) {
  return (state) => {
    if (succeeded) {
      return state.set("note", note);
    } else {
      return state.set("error", error);
    }
  };
}

function showCodeVerification(state) {
  return pushStep("ask_verification_code")(state);
}

function waiting(state) {
  return state.set("isLoading", true);
}

function nowaiting(state) {
  return state.without("isLoading");
}

function setProp(key, value) {
  return (state) => state.set(key, value);
}

function deleteProp(key) {
  return (state) => state.without(key);
}

function mergeProps(obj) {
  return (state) => state.merge(obj);
}

function pushStep(newState) {
  return (state) => {
    const oldStep = state.step;
    const states = state.prevSteps || Immutable([]);
    return state
      .merge({
        step: newState,
        prevSteps: Immutable([oldStep, ...states]),
      })
      .without("error")
      .without("note");
  };
}

function popStep() {
  return (state) => {
    const prevSteps = state.prevSteps;

    if (!prevSteps || prevSteps.length === 0) {
      throw new Error("Empty state stack");
    } else if (prevSteps.length === 1) {
      return state.set("step", prevSteps[0]).without("prevSteps");
    } else {
      return state
        .merge({
          step: prevSteps[0],
          prevSteps: prevSteps.slice(1),
        })
        .without("error")
        .without("note");
    }
  };
}
